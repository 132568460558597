import React, { useState } from "react";
import AppLayout from "../../components/app/AppLayout"
import AppointmentsByProvider from "../../components/app/Screens/AppointmentsByProvider";
import StatsBox from "../../components/app/Screens/StatsBox";
import useUser from "../../lib/useUser";
import Loading from "../../components/common/Loading";
import moment from 'moment-timezone';
import Head from "next/head";
import ProductUpdatesTicker from "../../components/app/Screens/ProductUpdatesTicker";
import _ from "lodash";
import plans from '../../plans'
import { FcLock } from "react-icons/fc"
import Link from "next/link"
import { hasPremiumPlan } from '../../services/frontendServices/subscriptionService'
import BusinessLink from "../../components/app/Screens/homePage/BusinessLink"
moment.tz.setDefault("Asia/Jerusalem");
moment.locale('he');

function Index() {
	const { user } = useUser({ redirectTo: '/app/login' })
	const plan = user?.billing?.subscription?.plan
	const groupsEnabled = hasPremiumPlan(plan)

	if (!user || user.isLoggedIn === false) {
		return <Loading />
	}

	const dayNum = moment().day() + 1;
	const operatingHours = _.find(_.get(user, 'business.timeSettings.availability', []), { day: dayNum });

	return (
		<div>
			<Head>
				<title>זמן טוב | העסק שלי</title>
			</Head>
			<div className="flex flex-col space-y-6">
				<div className="md:hidden">
					<BusinessLink pageCode={user.business.pageCode} />
				</div>
				<ProductUpdatesTicker />
				<div
					className="flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-0 md:space-x-6 md:space-x-reverse">
					<div className='w-full md:w-1/2 space-y-6'>
						<div>
							<h5 className="text-lg md:text-xl text-gray-900 font-semibold">
								היום, יום {moment().format('dddd, LL')}
							</h5>
							<div className='pb-3 tracking-tight'>
								{operatingHours.active ?
									<div className='space-x-2 space-x-reverse flex items-center'>
										<span className='bg-green-50 font-medium px-3 rounded text-green-600'>העסק פתוח</span>
										<span className='tracking-tight text-gray-700'>
											{`שעות פעילות: ${operatingHours.startTime} עד ${operatingHours.endTime}`}
										</span>
									</div>
									:
									<div className='space-x-2 space-x-reverse flex items-center'>
										<span className='bg-red-50 font-medium px-3 rounded text-red-600'>העסק סגור</span>
										<span className='tracking-tight text-gray-700'>לקוחות לא יכולים לקבוע תור</span>
									</div>
								}
							</div>
							<div className="mb-5">
								<h1 className="font-medium mb-2">תורים</h1>
								<div className='bg-white border border-gray-200 rounded-lg'>
									<StatsBox isGroupsStats={false} />
								</div>
							</div>
							<div>
								<div className="flex mb-2">
									<h1 className="font-medium">שיעורים</h1>
									{
										!groupsEnabled &&
										<span className='text-sm mr-2 text-gray-700 bg-red-100 py-0.5 px-1 font-medium flex items-center'>
											<span className='ml-1'><FcLock /></span>
											<span>לא כלול בחבילה שלך</span>
										</span>
									}
								</div>
								<div className='bg-white border border-gray-200 rounded-lg'>
									{
										groupsEnabled ?
											<StatsBox isGroupsStats={true} />
											:
											<div className="p-4 text-sm text-gray-900 flex flex-col justify-center"
												style={{ minHeight: '6.1rem' }}>
												<div className='mb-1'>
													החבילה {plans[plan].hebrew} אינה כוללת אפשרות להוסיף שיעורים.
												</div>
												<div>
													<span className='text-blue-700 font-semibold bg-blue-50 px-1 rounded leading-normal ml-1'>
														<Link href="/app/account/upgrade/">
															לחצו כאן
														</Link>
													</span>
													<span className='ml-1'>על מנת לשדרג את החבילה.</span>
												</div>
											</div>
									}
								</div>
							</div>
						</div>
						<div className='hidden md:block'>
							<BusinessLink pageCode={user.business.pageCode} />
						</div>
					</div>
					<div className='w-full md:w-1/2 space-y-2'>
						<div className='space-y-3'>
							<h5 className="text-lg md:text-xl pb-2 text-gray-600">מי מגיע היום</h5>
							<div className="bg-white border border-gray-200 rounded-lg">
								<AppointmentsByProvider />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Index.Layout = AppLayout;
export default Index
