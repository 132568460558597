import React from 'react';
import { AiOutlineLoading } from "react-icons/ai";

function LoadingComponent({ size }) {
	return (
		<div className='h-full flex flex-col items-center justify-center p-4 text-xl'>
			<AiOutlineLoading className='mx-auto animate-spin text-gray-500' size={size || 40} />
		</div>
	);
}

export default LoadingComponent;

