import React from 'react';
import StatItem from "./StatItem";
import { FcOvertime, FcTodoList, FcLike, FcMoneyTransfer } from "react-icons/fc";
import { useAPI } from "../../../contexts/api";
import LoadingComponent from "../../common/LoadingComponent";
import useSWR from "swr";

function StatsBox({ isGroupsStats }) {
	const { getStatsBox } = useAPI();
	const res = useSWR(`/api/business/stats`, getStatsBox);
	if (!res) return (<LoadingComponent />)
	const { data } = res;
	if (!data) return (<LoadingComponent />)
	const { stats } = data.data;
	if (!stats) return (<LoadingComponent />)

	const { registeredCustomersNum, appointments, expectedAppointmentsIncome, expectedGroupsIncome } = stats
	return (
		<div className="grid divide-gray-200 grid-cols-2 w-full">
			{/* <StatItem text='תורים שהושלמו' value={stats.completedAppointments || 0} icon={<FcTodoList size={31} />} /> */}
			{/* <StatItem text='לקוחות חדשים' value={stats.newCustomers || 0} icon={<FcLike size={30} />} /> */}
			<StatItem text='מגיעים היום' value={(isGroupsStats ? registeredCustomersNum : appointments) || 0} icon={<FcOvertime size={32} />} />
			<StatItem text='הכנסה מוערכת' value={'₪' + ((isGroupsStats ? expectedGroupsIncome : expectedAppointmentsIncome) || 0)} icon={<FcMoneyTransfer size={32} />} />
		</div>
	);
}

export default StatsBox;
