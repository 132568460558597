import React from 'react';
import { useAPI } from "../../../contexts/api";
import useUser from "../../../lib/useUser";
import { FiPhone } from "react-icons/fi";
import LoadingComponent from "../../common/LoadingComponent";
import uniqolor from 'uniqolor';
import { SiWhatsapp } from "react-icons/si";
import moment from "moment-timezone";
import useSWR from "swr";
import { CALENDAR_TYPES } from '../../../constants'

function AppointmentsByProvider() {
	const { user } = useUser();
	const { getWorkEvents } = useAPI();
	const res = useSWR('/api/business/appointments', () => getWorkEvents({ group: 'time' }));
	if (!res) return (<LoadingComponent />)
	const { data } = res;
	if (!data) return (<LoadingComponent />)
	let { workEvents } = data.data;

	if (user.business.generalSettings.filterCancelledAppointments) {
		for (let i = 0; i < workEvents.length; i++) {
			workEvents[i].workEvents = workEvents[i].workEvents.filter(app => {
				return app.status !== "CANCELLED";
			})
		}
	}

	workEvents = workEvents.filter(workEvents => {
		return workEvents.workEvents.length > 0;
	})

	return (
		<div className='md:h-96 lg:h-auto md:overflow-y-auto direction-rtl'>
			{workEvents.length === 0 &&
				<div className='p-5 text-gray-500 text-center'>
					כרגע אין משהו מעניין... אנחנו נשלח לכם הודעה מרגשת ברגע שמישהו קבע תור :)
				</div>}
			{workEvents.map((hour, idxer) => (
				<div key={hour._id} className='divide-y'>
					<div
						className={`font-bold text-gray-800 border-r-8 border-gray-500 ${idxer === 0 ? 'rounded-t-lg' : ''}`}>
						<div className='w-20 border-l text-base bg-gray-100 py-1 pr-4 px-3 tracking-tight'>
							{hour._id < 10 ? '0' + hour._id : hour._id}:00
						</div>
					</div>
					<div>
						{hour.workEvents.map((workEvent, idx) => (
							<div key={workEvent._id}>
								{
									workEvent.type === CALENDAR_TYPES.GROUP ?
										<div
											className={`flex flex-col ${idx === 0 ? '' : 'border-t'} ${idx === hour.workEvents.length - 1 && idxer !== workEvents.length - 1 && 'border-b'} `}>
											<div className='flex items-center w-full justify-between'>
												<div className='flex items-center'>
													<div className='tracking-tight text-gray-500 flex flex-col justify-center items-center border rounded p-1 m-2 shadow-sm text-sm w-20'>
														<div className=' flex flex-col'>
															<span className='text-sm sm:text-base text-gray-700 font-bold tracking-tight'>
																{workEvent.startTime}
															</span>
															<span className='text-xs text-gray-500 tracking-tight -mt-0.5 font-normal'>
																עד {workEvent.endTime}
															</span>
														</div>
														<div className='flex flex-1 leading-none w-full'>
															<div
																className='text-center w-full px-2 rounded py-px rounded-br-sm text-xs justify-center items-center mt-1 text-gray-500 font-medium space-x-reverse bg-gray-100'>שיעור</div>
														</div>
													</div>
													<div className='text-sm md:pr-1'>
														<div className='text-xs'>
															<span className="inline-block w-2 h-2 ml-1 rounded-full"
																style={{ background: uniqolor(workEvent.serviceProvider._id).color }} />
															{workEvent.serviceProvider.firstName}
														</div>
														<div className='text-black font-semibold'>
															{workEvent.groupName}
														</div>
														<div className='text-xs text-gray-600'>
															רשומים: {workEvent.customersIds.length || 0}
														</div>
													</div>
												</div>
											</div>
											{
												workEvent.comment && workEvent.comment.length > 0 &&
												<div className='p-1'>
													<div className='text-xs bg-yellow-50 w-full p-2 text-yellow-600 rounded-lg italic'>
														{workEvent.comment}
													</div>
												</div>
											}
										</div>
										:
										<div
											className={`${workEvent.status === 'CANCELLED' && 'opacity-50'} flex flex-col ${idx === 0 ? '' : 'border-t'} ${idx === hour.workEvents.length - 1 && idxer !== workEvents.length - 1 && 'border-b'}`}>
											<div className='flex items-center w-full justify-between'>
												<div className='flex items-center'>
													<div className='tracking-tight text-gray-500 flex flex-col justify-center items-center border rounded p-1 m-2 shadow-sm text-sm w-20'>
														<div className='flex flex-col'>
															<span className='text-sm sm:text-base text-gray-700 font-bold tracking-tight'>
																{workEvent.time.formattedTime}
															</span>
															<span className='text-xs text-gray-500 tracking-tight -mt-0.5 font-normal'>
																עד {moment(workEvent.time.formattedTime, 'HH:mm').add(workEvent.serviceDetails[0].length, 'minutes').format('HH:mm')}
															</span>
														</div>
														<div className='flex flex-1 leading-none w-full'>
															{workEvent.status === 'CANCELLED' &&
																<div
																	className='text-center w-full px-2 rounded py-px text-xs justify-center items-center mt-1 text-red-500 font-medium space-x-reverse bg-red-50'>בוטל</div>}
															{workEvent.status === 'CONFIRMED' &&
																<div
																	className='text-center w-full px-2 rounded py-px rounded-br-sm text-xs justify-center items-center mt-1 text-green-500 font-medium space-x-reverse bg-green-50'>
																	{_.get(workEvent.customerDetails[0], 'gender', 'לא קיים') === 'male' ? 'מגיע' : _.get(workEvent.customerDetails[0], 'gender', 'לא קיים') === 'female' ? 'מגיעה' : 'מגיע/ה'}
																</div>}
															{workEvent.status === 'NOT_CONFIRMED' &&
																<div
																	className='text-center w-full px-2 rounded py-px rounded-br-sm text-xs justify-center items-center mt-1 text-gray-500 font-medium space-x-reverse bg-gray-100'>מוזמן</div>}
														</div>
													</div>
													<div className='text-sm md:pr-1'>
														<div className='text-xs'>
															<span className="inline-block w-2 h-2 ml-1 rounded-full"
																style={{ background: uniqolor(workEvent.serviceProvider[0]._id).color }} />
															{workEvent.serviceProvider[0].firstName}
														</div>
														<div className='text-black font-semibold'>
															{workEvent.serviceDetails[0].name}
														</div>
														<div className='text-xs text-gray-600'>
															לקוח/ה: {_.get(workEvent.customerDetails[0], 'firstName', 'לא קיים')} {_.get(workEvent.customerDetails[0], 'lastName', 'לא קיים')}
														</div>
													</div>
												</div>
												<div
													className='tracking-tight pl-2 text-green-600 flex flex-col md:flex-row md:space-x-1 md:space-x-reverse md:space-y-0 space-y-1'>
													<a onClick={e => e.stopPropagation()}
														className='bg-white hover:bg-gray-50 h-8 lg:h-10 flex items-center text-gray-500 text-lg font-medium py-1 px-1.5 lg:px-2 border border-gray-200 rounded shadow-sm'
														href={`tel:${_.get(workEvent.customerDetails[0], 'phone', '000')}`}>
														<FiPhone />
													</a>
													<a onClick={e => e.stopPropagation()}
														className='bg-white hover:bg-gray-50 h-8 lg:h-10 hidden md:flex items-center text-green-500 text-lg font-medium py-1 px-1.5 lg:px-2 border border-gray-200 rounded shadow-sm'
														target='_blank'
														href={`/redirect?external=true&link=https://wa.me/972${_.get(workEvent.customerDetails[0], 'phone', '000')}`}>
														<SiWhatsapp />
													</a>
													<a onClick={(e) => e.stopPropagation()}
														className='bg-white hover:bg-gray-50 h-8 lg:h-10 md:hidden flex items-center text-green-500 text-lg font-medium py-1 px-1.5 lg:px-2 border border-gray-200 rounded shadow-sm'
														target='_blank' href={`/redirect?external=true&link=whatsapp://send?phone=+972${_.get(workEvent.customerDetails[0], 'phone', '000')}`}>
														<SiWhatsapp />
													</a>
												</div>
											</div>
											{
												workEvent.businessNotes && workEvent.businessNotes.length > 0 &&
												<div className='p-1'>
													<div className='text-xs bg-yellow-50 w-full p-2 text-yellow-600 rounded-lg italic'>
														{workEvent.businessNotes}
													</div>
												</div>
											}
											{
												workEvent.customerComment && workEvent.customerComment.length > 0 &&
												<div className='p-1'>
													<div className='text-xs bg-indigo-50 w-full p-2 text-indigo-600 rounded-lg italic'>
														{workEvent.customerComment}
													</div>
												</div>
											}
										</div>
								}
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
}

export default AppointmentsByProvider;