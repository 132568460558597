import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard"
import { IoCopyOutline } from "react-icons/io5"
import { FaWhatsapp } from "react-icons/fa"

function BusinessLink({ pageCode }) {
    const [copied, setCopied] = useState(false)

    const whatsappText = `היי :) מעכשיו ניתן לקבוע אליי תור בקלות ובמהירות, ביום ובשעה שנוחה לך. זה הלינק: https://zmantov.co.il/b/${pageCode}/`

    return (
        <section className='space-y-3'>
            <div className="bg-white border-2 border-dashed border-gray-300 rounded-lg">
                <div className="p-3 py-4 text-center space-y-2">
                    <p className='text-gray-500 tracking-tights'>שתפו את הקישור עם הלקוחות ותנו להם<br />לקבוע תורים בעצמם</p>
                    <div className='flex items-center flex-col justify-between space-y-3'>
                        <a className='text-blue-700 font-semibold bg-blue-50 px-2 rounded leading-normal text-sm md:text-base pt-1 sm:pt-0'
                            target='_blank' href={`https://zmantov.co.il/b/${pageCode}/`}>https://zmantov.co.il/b/{pageCode}
                        </a>

                        <div className='flex space-x-1 space-x-reverse'>
                            <CopyToClipboard text={`https://zmantov.co.il/b/${pageCode}/`} onCopy={() => setCopied(true)}>
                                <button
                                    className={` ${copied ? 'text-green-500 border-green-400 bg-green-50 font-semibold' : 'text-gray-800 border-gray-400 hover:bg-gray-100'} bg-white text-xs md:text-sm py-1 px-2 border rounded shadow flex items-center justify-center copy-to-clipboard-btn`}>
                                    <IoCopyOutline />
                                    <span className='mr-2'>{copied ? 'הועתק' : 'העתקה'}</span>
                                </button>
                            </CopyToClipboard>
                            <a className='md:hidden flex bg-white text-xs py-1 px-2 border rounded shadow flex items-center justify-center text-gray-800 border-gray-400 hover:bg-gray-100 space-x-1 space-x-reverse'
                                target="_blank"
                                href={`/redirect?external=true&link=whatsapp://send?text=${whatsappText}`}>
                                <span>שתפו ב-</span>
                                <FaWhatsapp className='text-green-500' size={16} />
                            </a>
                            <a className='hidden md:flex bg-white text-sm py-1 px-2 border rounded shadow flex items-center justify-center text-gray-800 border-gray-400 hover:bg-gray-100 space-x-1 space-x-reverse'
                                target="_blank"
                                href={`/redirect?external=true&link=https://wa.me/send?text=${whatsappText}`}>
                                <span>שתפו ב-</span>
                                <FaWhatsapp className='text-green-500' size={17} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default BusinessLink