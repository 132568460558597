import React, { useEffect, useState } from 'react';
import useSWR from "swr";
import { useAPI } from "../../../contexts/api";
import Ticker from 'react-ticker'
import PageVisibility from "react-page-visibility";
import moment from 'moment-timezone';
import { useRouter } from 'next/router'

function ProductUpdatesTicker(props) {
  const [pageIsVisible, setPageIsVisible] = useState(true);
  const [showBadge, setShowBadge] = useState(false);
  const router = useRouter()

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  const { getProductUpdates } = useAPI();
  const { data, error } = useSWR(`/productUpdates`, getProductUpdates);

  useEffect(() => {
    if (data) {
      const numUpdates = localStorage.getItem('numUpdates');
      if (!numUpdates || (numUpdates && data && data.length > parseInt(numUpdates))) {
        setShowBadge(true)
      }
    }
  }, [data])

  const goToFullpage = () => {
    localStorage.setItem('numUpdates', data.length);
    setShowBadge(false);
    router.push('/app/updates');
  }

  if (!data) return '';

  const getNews = () => {
    return (
      <div className="flex items-center w-full justify-between">
        {
          data.map(news =>
            <div key={news._id} className="whitespace-nowrap w-full flex items-center space-x-3 space-x-reverse pr-16">
              <div className="flex items-center">
                {news.type === 'fix' && <span
                  className="px-2 py-1 text-xs font-bold leading-none text-white bg-blue-600 rounded-full">תיקון</span>}
                {news.type === 'improvement' && <span
                  className="px-2 py-1 text-xs font-bold leading-none text-white bg-indigo-600 rounded-full">שיפור</span>}
                {news.type === 'new' && <span
                  className="px-2 py-1 text-xs font-bold leading-none text-white bg-green-600 rounded-full">חדש</span>}
              </div>
              <div className="flex flex-col w-full whitespace-nowrap">
                <div className='text-xs text-gray-700'>{moment(news.createdAt).format('DD/MM/YYYY')}</div>
                <div className='w-full whitespace-nowrap'>{news.title}</div>
              </div>
            </div>)
        }
      </div>)
  }

  return (
    <div className='space-y-3 cursor-pointer' onClick={() => goToFullpage()}>
      <h1 className="text-lg md:text-xl text-gray-600 pb-2 flex items-center">מה חדש באפליקציה
        {showBadge &&
          <div
            className='animate-pulse animate-bounce w-6 h-6 text-center font-bold bg-red-600 text-white mr-2 text-sm flex items-center justify-center rounded-full border shadow border-red-300'>
            1
          </div>
        }
      </h1>
      <div className="bg-gray-100 p-2 rounded">
        <PageVisibility onChange={handleVisibilityChange}>
          {pageIsVisible && (
            <Ticker speed={3}>
              {() => getNews()}
            </Ticker>
          )}
        </PageVisibility>
      </div>
    </div>
  );
}

export default ProductUpdatesTicker;