import React from 'react';

function StatItem({ text, icon, value }) {
	return (
		<div className="statItem flex items-center justify-between py-4 px-3 md:px-5">
			<div className="space-y-1">
				<div className="text-gray-500 text-sm md:text-base">{text}</div>
				<div className="text-xl md:text-3xl font-semibold text-gray-800">{value}</div>
			</div>
			{icon && <div className="text-indigo-600 text-3xl">
				{icon}
			</div>}
		</div>
	);
}

export default StatItem;
